<template>
    <b-form id="formularioLogin" @submit="enviarLogin" validate>
            <b-form-row>
                <b-col cols="12" v-if="!recuperacion">
                    <b-form-group
                        id="group-login"
                        label="Ingrese usuario"
                        label-for="login"
                    >
                        <b-form-input
                            id="login"
                            v-model="form.login"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" v-if="!recuperacion">
                    <b-form-group
                        id="group-password"
                        label="Ingrese contraseña"
                        label-for="password"
                    >
                        <b-form-input
                            id="password"
                            v-model="form.password"
                            type='password'
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" v-if="recuperacion">
                    <b-form-group
                        id="group-email"
                        label="Ingrese el correo de recuperación"
                        label-for="email"
                    >
                        <b-form-input
                            id="email"
                            v-model="form.email"
                            type="email"
                            :required="recuperacion"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12"  v-if="!recuperacion">
                    <b-button variant="link" class="text-right mb-1" block size='md' @click="activarRecuperacion">
                        ¿Olvidé mi contraseña?
                    </b-button>
                </b-col>

                <b-col cols="12"  v-if="!recuperacion">
                    <b-button type="submit" variant="primary" class="mr-2" block size='md'>
                        Entrar
                    </b-button>
                </b-col>

                <b-col cols="12"  v-if="recuperacion">
                    <p>Se enviará al correo un link para recuperar la contraseña</p>
                </b-col>


                <b-col cols="12"  v-if="recuperacion">
                    <b-button type="submit" variant="primary" class="mr-2 mb-2" block size='md' :disabled="form.email==null || form.email==''">
                        Enviar Correo de recuperación
                    </b-button>
                </b-col>

                <b-col cols="12"  v-if="recuperacion && !registro">
                    <b-button type="button" variant="primary" class="mr-2" block size='md' @click="cancelarRecuperar">
                        Cancelar
                    </b-button>
                </b-col>
            </b-form-row>

    </b-form>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import {encontrarError} from '../../contantesErrores';

    export default {
        name:'FormularioLogin'  ,
        data(){
            return{
                recuperacion: false,
                form:{
                    login: '',
                    password: '',
                    email: ''
                }
            }
        },
        mounted(){
            localStorage.removeItem("persona");
            localStorage.removeItem("usuario");
        },
        computed:{
            ...mapState('usuarios', ['respuestaLogin', 'respuestaForgot']),
        },
        methods:{
            ...mapActions('usuarios', ['login', 'forgotPass']),

            activarRecuperacion(){
                this.recuperacion = true
            },  

            async enviarLogin(ev){
                ev.preventDefault()
                let datosEnviar
                if(!this.recuperacion){
                    datosEnviar ={
                        usuariologin: this.form
                    }
                    try{
                        await this.login(datosEnviar)
                        if(this.respuestaLogin.status==200){
                            let usuario = JSON.stringify(this.respuestaLogin.data[0])
                            localStorage.setItem('usuario', usuario)
                            localStorage.removeItem("persona");
                            this.$router.push({name: 'home'})
                        }
                    }catch(e){
                        encontrarError(this, e.data)
                    }
                }else{
                    datosEnviar ={
                        email: this.form.email
                    }
                    try{
                        await this.forgotPass(datosEnviar)
                        if(this.respuestaForgot.status==200){
                            this.modalForgotOk();
                        }
                    }catch(e){
                        encontrarError(this, e.data)
                    }
                }
            },

            modalForgotOk(){
                this.$bvModal.msgBoxOk('Se ha enviado el correo de recuperación', {
                    title: 'Confirmación',
                    size: 'md',
                    buttonSize: 'md',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                    })
                    .then(() => {
                        this.$router.go()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },

            cancelarRecuperar(e){
                e.preventDefault();
                this.recuperacion = false
            }
        }
    }
</script>
<style scoped>
    #formularioLogin{
        width: 80%;
        margin-left: 10%
    }
    @media (max-width: 992px){
        #formularioLogin{
            width: 100%;
            margin-left: 0
        }
    }
</style>