<template>
    <b-form id="formularioLogin" @submit="enviarLogin" validate>
            <b-form-row>
               
                <b-col cols="12">
                    <b-form-group
                        id="group-password"
                        label="Ingrese la nueva contraseña"
                        label-for="password"
                    >
                        <b-form-input
                            id="password"
                            v-model="form.password"
                            type='password'
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-form-group
                        id="group-passwordCheck"
                        label="Repita la nueva contraseña"
                        label-for="passwordCheck"
                    >
                        <b-form-input
                            id="passwordCheck"
                            v-model="form.passwordCheck"
                            type="password"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" >
                    <p>Se restablecerá la contraseña de su cuenta con la indicada anteriormente</p>
                </b-col>

                <b-col cols="12" >
                    <b-button type="submit" variant="primary" class="mr-2" block size='md' :disabled="this.form.password==null || this.form.password != this.form.passwordCheck">
                        Enviar Contraseña
                    </b-button>
                </b-col>
            </b-form-row>

    </b-form>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import {encontrarError} from '../../contantesErrores';

    export default {
        name:'FormularioReset'  ,
        data(){
            return{
                form:{
                    password: null,
                    passwordCheck: null,
                }
            }
        },
        computed:{
            ...mapState('usuarios', ['tokenRecuperacion','resTokenRecuperacion']),
        },
        methods:{
            ...mapActions('usuarios', ['resetPass']),

            async enviarLogin(e){
                e.preventDefault()

                let datosEnviar={
                    tokencontrasena:{
                        token: this.tokenRecuperacion,
                        password: this.form.password
                    }
                }
                console.log(datosEnviar)
                try{
                        await this.resetPass(datosEnviar)
                        if(this.resTokenRecuperacion.status==200){
                            this.modalForgotOk();
                        }
                    }catch(e){
                        encontrarError(this, e.data)
                    }

            },

            modalForgotOk(){
                this.$bvModal.msgBoxOk('Se ha recuperado la contraseña', {
                    title: 'Confirmación',
                    size: 'md',
                    buttonSize: 'md',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                    })
                    .then(() => {
                        window.location="http://localhost:8081"
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
        }
    }
</script>
<style scoped>
    #formularioLogin{
        width: 80%;
        margin-left: 10%
    }
    @media (max-width: 992px){
        #formularioLogin{
            width: 100%;
            margin-left: 0
        }
    }
</style>